@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background: white;

}

.section-container {
  @apply max-w-screen-2xl container mx-auto xl:px-24 px-4
}


.title {
  @apply text-4xl md:text-5xl font-bold my-2 md:leading-snug leading-snug
}
.heartstar{
  border-radius: 0px 28px 0px 28px;
}
/* .navbar-item.active {
  text-decoration: underline;
  text-underline-offset: 5px;
color:rgb(4, 168, 15)
} */

input {
  background-color: white;  /* Set background to white */
}